class Locale {
    altTip?: string;
    floatLeft?: string;
    floatRight?: string;
    center?: string;
    restore?: string;
  }

class I18n {

    config: Locale;

    constructor(config: Locale) {
        this.config = { ...defaultLocale, ...config };
    }

    findLabel(key: string): string | null {
        if (this.config) return Reflect.get(this.config, key);
        
        return null;
    }
}
  
const defaultLocale: Locale = {
    altTip: 'Mantené apretado ALT para hacer zoom', 
    floatLeft: 'Izquierda',
    floatRight: 'Derecha',
    center: 'Centrar',
    restore: 'Restaurar',
};

export { I18n, Locale, defaultLocale };